import React, { PureComponent } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import Home from '../Home/Home';

import './Layout.css';

class Layout extends PureComponent {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Layout;
