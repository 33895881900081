import React, { PureComponent } from 'react';

import Layout from './Layout/Layout';

class App extends PureComponent {
  render() {
    return (
      <div className="App">
        <Layout />
      </div>
    );
  }
}

export default App;
